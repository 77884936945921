// else if (component.__typename === "CorpGenericAccordion") {
//   componentData = await getCorpAccordionData(locale, component.id);
// }
// else if (component.__typename === "LocationComponent") {
//   componentData = await getLocationComponentData(locale, stage, component.id);
// } else {

// import { lowerCaseFirstLetter } from 'lib/utils/helpers';
import { log } from 'lib/utils/logger';
import { getAudioComponent } from './getAudioComponent';
import { getAuthorableContentCard } from './getAuthorableContentCard';
import { getCorpBanner1 } from './getBanner1';
import { getBanner2 } from './getBanner2';
import { getCarouselComponent } from './getCarouselComponent';
import { getCorpAccordionData } from './getCorpGenericAccordion';
import { getCorpHeroComponent } from './getCorpHero';
import { getDynamicCardGrid } from './getDynamicCardGrid';
import { getExampleComponent } from './getExampleComponent';
import { getFeaturedContent } from './getFeaturedContent';
import { getIframe } from './getIframe';
import { getForm } from './getForm';

import { getGenericAccordion } from './getGenericAccordion';
import { getGenericAccordionTopRated } from './getGenericAccordionTopRated';
import { getGenericCardCarouseComponent } from './getGenericCardCarouseComponent';
import { getGenericDownloadComponent } from './getGenericDownloadComponent';
import { getGenericFollowUs } from './getGenericFollowUs';
import { getGenericGridAuthorable } from './getGenericGridAuthorable';
import { getGenericLine } from './getGenericLine';
// TODO 2199 remove following line when GridColumnComponent no longer in use in cms
import { getCorpGridColumnComponent } from './getCorpGridColumnComponent';
import { getHeroCarouselComponent } from './getHeroCarouselComponent';
import { getHeroComponent } from './getHeroComponent';
import { getImageCopy } from './getImageCopy';
import { getIntroText } from './getIntroText';
import { getLink } from './getLink';
import { getLocationComponent } from './getLocationComponent';
import { getLongText } from './getLongText';
import { getProgressBar } from './getProgressBar';
import { getQuickLink } from './getQuickLinkComponent';
import { getShortHeroComponent } from './getShortHero';
import { getSpacer } from './getSpacer';
import { getTabs } from './getTabs';
import { getBigNumber } from './getBigNumber';
import { getCorpTimelineAccordionWrapper } from './getCorpTimelineAccordionWrapper';
import { getAnchorNavigationList } from './getAnchorNavigationList';
import { getCorpGenericContainer } from './getCorpGenericContainer';
import { getCorpOurPeople } from './getCorpOurPeople';
import { getCorpContactCardContainer } from './getCorpContactCardContainer';
import { getCorpMediaDownloadPreview } from './getCorpCorpMediaDownloadPreview';
import { getCorpCarouselComponentAndData } from './getCorpCardCarouselComponent';
import { getCorpVideoHeroComponent } from './getCorpVideoHero';
import { getCorpForm } from './getCorpForm';
import { getCorpVideoHeroCarouselComponent } from './getCorpVideoHeroCarousel';

/**
 *
 * This function recursively checks all Link-Components and overwrites
 * the `url` param with the internal link, if there is one.
 */
export function findInternalLink(obj: any, prefix: string) {
  let found = false;
  function finder(obj: any) {
    if (found) {
      return;
    }
    // if obj is an array, iterate
    if (Array.isArray(obj)) {
      for (const item of obj) {
        // if its an array, we have to call the parent function for all items
        findInternalLink(item, prefix);
      }
    } else {
      if (obj?.hasOwnProperty('__typename') && obj.__typename === 'Link') {
        if (
          obj.hasOwnProperty('internalLink') &&
          obj.internalLink?.hasOwnProperty('slug') &&
          obj.internalLink.slug !== null
        ) {
          obj.url = `${obj.internalLink.slug.charAt(0) == '/' ? '' : '/'}${obj.internalLink.slug}`;
        }
        found = true;
        return;
      }
      for (let key in obj) {
        if (typeof obj[key] === 'object') {
          finder(obj[key]);
        }
      }
    }
  }
  finder(obj);
}

type IComponent = {
  id: string;
  __typename: string;
};

export async function getGenericPageComponents(
  components: any[],
  locale: string,
  market: string | null,
): Promise<any> {
  if (components && components.length > 0) {
    const promiseCollector = components?.map(async (component: IComponent) => {
      if (component && component.__typename && component.id) {
        let componentData: any;
        try {
          switch (component.__typename) {
            case 'AnchorNavigationList':
              componentData = await getAnchorNavigationList(component.id);
              break;
            case 'AudioComponent':
              componentData = await getAudioComponent(component.id, locale);
              break;
            case 'AuthorableContentCard':
              componentData = await getAuthorableContentCard(component.id, locale);
              break;
            case 'CorpBanner1':
              componentData = await getCorpBanner1(component.id, locale);
              break;
            case 'Banner2':
              componentData = await getBanner2(component.id, locale);
              break;
            case 'CarouselComponent':
              componentData = await getCarouselComponent(component.id, locale);
              break;
            case 'CorpContactCardContainer':
              componentData = await getCorpContactCardContainer(component.id, locale);
              break;
            case 'DynamicCardGrid':
              componentData = await getDynamicCardGrid(locale, component.id);
              break;
            case 'ExampleComponent':
              componentData = await getExampleComponent(component.id, locale);
              break;
            case 'FeaturedContent':
              componentData = await getFeaturedContent(locale, component.id);
              break;
            case 'GenericGridAuthorable':
              componentData = await getGenericGridAuthorable(locale, component.id);
              break;
            case 'Form':
              componentData = await getForm(component.id, locale);
              break;
            case 'GenericAccordion':
              componentData = await getGenericAccordion(component.id, locale);
              break;
            case 'GenericAccordionTopRated':
              componentData = await getGenericAccordionTopRated(component.id, locale);
              break;
            case 'GenericCardCarouseComponent':
              componentData = await getGenericCardCarouseComponent(locale, component.id);
              break;
            case 'GenericDownloadComponent':
              componentData = await getGenericDownloadComponent(component.id, locale);
              break;
            case 'GenericFollowUs':
              componentData = await getGenericFollowUs(locale, component.id);
              break;
            case 'GenericLine':
              componentData = await getGenericLine(component.id, locale);
              break;
            case 'Link':
              componentData = await getLink(component.id, locale);
              break;
            // TODO 2199 remove following line when GridColumnComponent no longer in use in cms
            case 'CorpGridColumnComponent':
              componentData = await getCorpGridColumnComponent(component.id, locale);
              break;
            case 'HeroCarouselComponent':
              componentData = await getHeroCarouselComponent(component.id, locale);
              break;
            case 'ImageCopy':
              componentData = await getImageCopy(component.id, locale);
              break;
            case 'IntroText':
              componentData = await getIntroText(component.id, locale);
              break;
            case 'Iframe':
              componentData = await getIframe(component.id);
              break;
            case 'LongText':
              componentData = await getLongText(component.id, locale);
              break;
            case 'LocationComponent':
              componentData = await getLocationComponent(component.id, locale, market);
              break;
            case 'CorpCardCarousel':
              // This is a complex component, immediate return is required to avoid functions running twice on component data.
              // check `getCorpCarouselComponentAndData` for more info.
              return getCorpCarouselComponentAndData(component, locale, market);
            case 'CorpMediaDownloadPreview':
              componentData = await getCorpMediaDownloadPreview(component.id, locale);
              // we have to pull the latest downloads for this items, which will not show up in returned component data by default.
              // so add downloads manually to returned data
              // @TODO: make getGenericPageComponents more flexible
              if (componentData?.data) {
                findInternalLink(componentData.data[Object.keys(componentData.data)[0]], locale);
              }
              const corpMediaDownloadLowerCaseFirstChar =
                component.__typename.charAt(0).toLowerCase() + component.__typename.substring(1);
              return {
                ...componentData?.data[corpMediaDownloadLowerCaseFirstChar],
                downloads: { ...componentData?.data?.downloads },
              };
            case 'QuickLink':
              componentData = await getQuickLink(component.id, locale);
              break;
            case 'CorpTimelineAccordionWrapper':
              componentData = await getCorpTimelineAccordionWrapper(component.id, locale);
              break;
            case 'CorpHero':
              componentData = await getCorpHeroComponent(component.id, locale);
              break;
            case 'CorpForm':
              componentData = await getCorpForm(component.id, locale);
              break;
            case 'CorpVideoHero':
              componentData = await getCorpVideoHeroComponent(component.id, locale);
              break;
            case 'CorpVideoHeroCarousel':
              componentData = await getCorpVideoHeroCarouselComponent(component.id, locale);
              break;
            case 'CorpOurPeople':
              componentData = await getCorpOurPeople(component.id, locale);
              break;
            case 'ShortHero':
              componentData = await getShortHeroComponent(component.id, locale);
              break;
            case 'CorpGenericAccordion':
              componentData = await getCorpAccordionData(locale, component.id, market);
              break;
            case 'HeroComponent':
              componentData = await getHeroComponent(component.id, locale);
              break;
            case 'Spacer':
              componentData = await getSpacer(component.id, locale);
              break;
            case 'Tab':
              componentData = await getTabs(component.id, locale, market);
              break;
            case 'ProgressBar':
              componentData = await getProgressBar(component.id, locale);
              break;
            case 'BigNumber':
              componentData = await getBigNumber(component.id, locale);
              break;
            case 'CorpGenericContainer':
              componentData = await getCorpGenericContainer(component.id, locale, market);
              break;
            default:
              console.info('Reached default getGenericPageComponents', component.__typename);
              return {};
          }
          if (componentData?.data) {
            findInternalLink(componentData.data[Object.keys(componentData.data)[0]], locale);
          }
          // component typename firstchar is always UpperCase in GraphCms, convert to lowerCase to match query
          const lowerCaseFirstChar =
            component.__typename.charAt(0).toLowerCase() + component.__typename.substring(1);
          return componentData?.data[lowerCaseFirstChar];
        } catch (error: any) {
          log.error('getGenericPageComponents promise iteration', error);
          return {};
        }
      }
      return {};
    });
    try {
      return await Promise.all(promiseCollector);
    } catch (e) {
      throw new Error(`Error in retuning getGenericPageComponents ${e}`);
    }
  } else {
    // do not reject here to avoid breaking the page
    return Promise.resolve('');
  }
}
